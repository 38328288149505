import Text from '@ingka/text';
import { Stack, Page } from 'components/layout';
import React from 'react';

export const Fedsa = () => (
  <Page>
    <Stack>
      <Text bodySize="l">Hi, Welcome to FEDSA Application</Text>
    </Stack>
  </Page>
);
