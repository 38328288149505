import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import Home from 'pages/Home';
import AppHeader from 'components/AppHeader';
import { AboutPage } from 'pages/About';
import AppFooter from 'components/AppFooter';
import CustomersPage from 'pages/Customers';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'appInsights';
import { ToastAnchor } from 'components/ToastAnchor';
import { Fedsa } from 'pages/Fedsa';
const queryClient = new QueryClient();

const App = (props: { pca: IPublicClientApplication }) => (
  <MsalProvider instance={props.pca}>
    <AppInsightsErrorBoundary
      onError={() => <h1>Something went wrong</h1>}
      appInsights={reactPlugin}
    >
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <AppHeader />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/fedsa" element={<Fedsa />} />
          </Routes>
          <AppFooter />
          <ToastAnchor />
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  </MsalProvider>
);

export default App;
